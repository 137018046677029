


































































































































































































































































































import { Component, Prop } from 'vue-property-decorator'
import VueBase from '../../VueBase'
import SearchCard from '@/views/taint/searchCard.vue'
import PoolDetail from '@/views/taint/PoolDetail.vue'
import moment from 'moment'
@Component({
  name: 'ApiList',
  components: {
    SearchCard,
    PoolDetail,
  },
})
export default class Index extends VueBase {
  @Prop() versionId: number | undefined
  @Prop(String) projectId!: string
  private searchObj = {
    method: '',
    status: '',
    url: '',
  }
  private tableRowItem: any = {}
  private vlunList = []
  private parameters: any = []
  private visible = false
  private listPageInfo = {}
  private dataEnd = false
  private page_index = 1
  private pageSize = 20
  private coverRate = ''
  private totalCount = ''
  private coverCount = ''
  private openCollapse = []
  private apiList = []
  private dialogVisible = false
  private getDetail() {
    this.visible = true
  }
  private getColor(type: string) {
    switch (type) {
      case 'GET':
        return { borderColor: '#3B82F6', bgColor: '#EFF6FF' }
      case 'POST':
        return { borderColor: '#10B981', bgColor: '#ECFDF5' }
      case 'PUT':
        return { borderColor: '#F59E0B', bgColor: '#FFFBEB' }
      case 'DELETE':
        return { borderColor: '#EF4444', bgColor: '#FEF2F2' }
      case 'PATCH':
        return { borderColor: '#84CC16', bgColor: '#F7FEE7' }
      case 'OPTIONS':
        return { borderColor: '#0EA5E9', bgColor: '#F0F9FF' }
      case 'HEAD':
        return { borderColor: '#8B5CF6', bgColor: '#F5F3FF' }
      case 'DUBBO':
        return { borderColor: '#F97316', bgColor: '#FFF7ED' }
      default:
        return { borderColor: '#94A3B8', bgColor: '#F8FAFC' }
    }
  }
  private levelMap = {
    1: {
      name: '高危',
      bg: 'var(--color-risk-high-bg)',
      color: 'var(--color-risk-high)',
    },
    2: {
      name: '中危',
      bg: 'var(--color-risk-med-bg)',
      color: 'var(--color-risk-med)',
    },
    3: {
      name: '低危',
      bg: 'var(--color-risk-low-bg)',
      color: 'var(--color-risk-low)',
    },
    5: {
      name: '提示',
      bg: 'var(--color-risk-tip-bg)',
      color: 'var(--color-risk-tip)',
    },
  }
  private id = ''
  private async startView(item: any) {
    const res = await this.services.project.relationrequest({
      project_id: this.projectId,
      version_id: this.versionId,
      api_route_id: item.id,
    })
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    if (item.is_cover) {
      this.id = res.data.id
      if (!this.id) this.$message.error('未找到最近的请求信息')
      const { href } = this.$router.resolve(`/poolDetail/${this.id}`)
      window.open(href, '_blank')
      // this.dialogVisible = true
      return
    }
    if (item.showSend) {
      item.showSend = false
    }
    item.url = res.data.url
    item.poolId = res.data.id
    item.req_header_fs = res.data.req_header_fs
    item.req_data = res.data.req_data
    item.res_body = res.data.res_body
    item.res_header = res.data.res_header
    item.update_time = res.data.update_time
    if (res.status)
      this.$nextTick(() => {
        item.showSend = true
      })
    this.tableRowItem = item
  }
  private startSend(item: any) {
    if (item.showSend) {
      item.showSend = false
    }
    this.$nextTick(() => {
      item.showSend = true
    })
  }
  private async getApiList() {
    this.loadingStart()

    let params = {
      page_size: this.pageSize,
      page: this.page_index,
      uri: this.searchObj.url,
      http_method: this.searchObj.method,
      is_cover: this.searchObj.status,
      project_id: this.projectId,
      version_id: this.versionId,
    }
    for (let key in params) {
      if (params[key] === '' || params[key] === null) {
        delete params[key]
      }
    }

    const res = await this.services.project.searchApi(params)
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
    }
    this.listPageInfo = res.page || {}
    const apiList = res.data.map((item: any) => {
      return {
        id: item.id,
        agent: item.agent,
        poolId: -1,
        parameters: item.parameters,
        path: item.path,
        vulnerablities: item.vulnerablities,
        method: item.method.toUpperCase(),
        httpMethod: item.method.toUpperCase(),
        description: item.description,
        is_cover: item.is_cover,
        // return_type: item.responses[0] && item.responses[0].return_type,
        // return_type_shortcut:
        //   item.responses[0] && item.responses[0].return_type_shortcut,
        req_header_fs: '',
        // req_header_fs: `${item.method.httpmethods[0]} ${item.path} HTTP/1.1`,
        req_data: '',
        res_header: '',
        res_body: '',
        showSend: false,
        create_at: moment(new Date(item.create_at)).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        update_at: moment(new Date(item.update_at)).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
      }
    })
    this.apiList = apiList
    // this.apiList = this.apiList.concat(apiList)
    // if (apiList.length < this.pageSize) {
    //   this.dataEnd = true
    // }
  }

  private resetData() {
    this.apiList = []
    this.page_index = 1
    this.dataEnd = false
    this.pageSize = 20
    this.searchObj.url = ''
    this.searchObj.method = ''
    this.searchObj.status = ''

    this.getApiList()
  }

  private searchChange() {
    this.apiList = []
    this.page_index = 1
    this.dataEnd = false
    this.getApiList()
  }

  private async cover() {
    const res = await this.services.project.coverRate({
      project_id: this.projectId,
      version_id: this.versionId,
    })
    if (res.status !== 201) {
      this.$message.error(res.msg)
    }
    this.coverRate = res.data.cover_rate
    this.totalCount = res.data.total_count
    this.coverCount = res.data.covered_count
  }

  private content: Element | null = null
  async mounted() {
    this.cover()
    this.pageSize = Math.ceil((document.body.clientHeight - 280) / 48)
    await this.getApiList()
    this.content = document.querySelector('.layoutMain')
    // this.content && this.content.addEventListener('scroll', this.myScroll)
  }

  beforeDestroy() {
    // this.content && this.content.removeEventListener('scroll', this.myScroll)
  }

  handleCurrentChange(val: any) {
    this.page_index = val
    this.getApiList()
  }

  private myScroll() {
    let bottomWindow = false
    if (this.content) {
      console.log(
        this.content.scrollTop,
        this.content.clientHeight,
        this.content.scrollHeight
      )
      bottomWindow =
        this.content.scrollTop + this.content.clientHeight >=
        this.content.scrollHeight - 50
    }

    if (bottomWindow) {
      if (!this.dataEnd) {
        this.page_index += 1
        this.getApiList()
      }
    }
  }

  private handleClick(item: any) {
    this.visible = true
    setTimeout(async () => {
      const { data, status, msg } = await this.services.project.detailApi(
        item.id
      )
      // this.loadingDone()
      if (status !== 201) {
        this.$message.error(msg)
        return
      }
      let { parameters, response } = this.formatterApiinfo(data)
      item.parameters = parameters
      item.response = response
      item.vlunList = data.vulnerablities
      this.tableRowItem.parameters = parameters
      this.tableRowItem.response = response
      this.tableRowItem.vlunList = data.vulnerablities
      this.vlunList = data.vulnerablities
      this.parameters = parameters
      this.tableRowItem.create_at = moment(new Date(data.create_at)).format(
        'YYYY-MM-DD HH:mm:ss'
      )
      this.tableRowItem = data
    })
  }

  private formatterApiinfo(data: any) {
    let response: string = ''
    let parametersList: any[] = []
    let { api_info, method, path } = data
    let { parameters, responses } = api_info.paths[path][method.toLowerCase()]
    let str = JSON.stringify(responses || {})
    if (str.includes('#/components/schemas')) {
      response =
        str
          .split('"')
          .find((item) => item.includes('#/components/schemas'))
          ?.split('/')
          .pop() || ''
    } else if (str.includes('type')) {
      response = str.match(/type":"(\w+)"/)?.[1] || ''
    }

    parameters &&
      parameters.forEach((ele: any) => {
        parametersList.push({
          name: ele.name,
          type: ele.schema.type || ele.schema['$ref'].split('/').pop(),
          annotation: ele.in,
        })
      })
    return {
      parameters: parametersList,
      response,
    }
  }

  private formatterTitle(item: any) {
    let title = ''
    title = `${item.uri}  ${item.http_method} 出现 ${item.type}`
    if (item.taint_position) {
      title += '位置:' + item.taint_position
    }
    return title
  }

  private goDetail(item: any) {
    const { href } = this.$router.resolve(
      `/project/leakDetail/1/${item.id}?status=` + '&id=' + item.id
    )
    window.open(href, '_blank')
  }
}
